import React, { useEffect, useState } from 'react'
import {FiUserCheck, FiUsers, FiUserX} from 'react-icons/fi'
import { FaRegEye, FaSuitcase } from 'react-icons/fa'
import { HiOutlineCursorClick } from "react-icons/hi";
import { CiFilter } from "react-icons/ci";
import { useJobContext } from '../../../context';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { Graph } from 'react-chartjs-2';
import MediaQuery from 'react-responsive';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const EmployerDash = () => {
    const admin = JSON.parse(sessionStorage.getItem('data'));
    const { getApplicationsByAdminId } = useJobContext();
    const [jobsData, setJobsData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch job data
    const fetchData = async () => {
        try {
            const jobsData = await getApplicationsByAdminId(admin?.id);
            if (jobsData && jobsData.length > 0) {
                setJobsData(jobsData);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [jobsData]);

    const totalJobsPosted = jobsData?.length || 0;
    const totalClicks = jobsData.reduce((acc, job) => acc + job.clicks, 0);
    const totalViews = jobsData.reduce((acc, job) => acc + job.views, 0);
    const totalApplicants = jobsData.reduce((acc, job) => acc + job.applicants, 0);
    const totalSelectedApplicants = jobsData.reduce((acc, job) => acc + job.selected, 0);
    const totalRejectedApplicants = jobsData.reduce((acc, job) => acc + job.rejected, 0);
    const applyRate = totalViews ? (totalApplicants / totalViews * 100) : 0;

    // Pie chart data for selected vs rejected applicants
    const pieData = {
        labels: ["Selected", "Rejected",'Total Jobs', 'Total Clicks', 'Total Views', 'Total Applicants'],
        datasets: [
            {
                data: [totalSelectedApplicants, totalRejectedApplicants,totalJobsPosted, totalClicks, totalViews, totalApplicants],
                backgroundColor: ['#f44336', '#48c9b0', '#2471a3', '#f39c12', '#aab7b8', '#DE3163'],
            },
        ],
    };
    const graphData = {
        labels: ['Total Jobs', 'Total Clicks', 'Total Views', 'Total Applicants', 'Selected', 'Rejected'],
        datasets: [
            {
                label: 'Count',
                data: [totalJobsPosted, totalClicks, totalViews, totalApplicants, totalSelectedApplicants, totalRejectedApplicants],
                backgroundColor: ['#2471a3', '#f39c12', '#aab7b8', '#DE3163', '#48c9b0', '#f44336'],
            },
        ],
    };

    return (
        <div className='conatainer-fluid py-4'>
            <div className="me-10 form-group col-lg-3">
                <select  className='form-select'>
                    <option value="">Recent</option>
                    <option value="">Last 10 Days</option>
                </select>
            </div>
            <div className="row">
                <div className="col-lg-4 py-3 px-2">
                    <div className="r1 card px-2">
                        <div className="card-body row">
                            <div className="col-lg">
                                <p>{jobsData?.length}</p>
                                <p>Total job posted</p>
                            </div>
                            <div className="col-lg flex items-center justify-center">
                                <FaSuitcase size={35} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 py-3 px-2">
                    <div className="r2 card px-2">
                        <div className="card-body row">
                            <div className="col-lg">
                                <p>{totalClicks}</p>
                                <p>Total clicks</p>
                            </div>
                            <div className="col-lg flex items-center justify-center">
                                <HiOutlineCursorClick size={35} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 py-3 px-2">
                    <div className="r3 card px-2">
                        <div className="card-body row">
                            <div className="col-lg">
                                <p>{totalViews}</p>
                                <p>job views</p>
                            </div>
                            <div className="col-lg flex items-center justify-center">
                                <FaRegEye size={35} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 py-3 px-2">
                    <div className="r4 card px-2">
                        <div className="card-body row">
                            <div className="col-lg">
                                <p>{totalApplicants}</p>
                                <p>Total Applicants</p>
                            </div>
                            <div className="col-lg flex items-center justify-center">
                                <FiUsers size={35} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 py-3 px-2">
                    <div className="r5 card px-2">
                        <div className="card-body row">
                            <div className="col-lg">
                                <p>{totalSelectedApplicants}</p>
                                <p>Total Selected Applicants</p>
                            </div>
                            <div className="col-lg flex items-center justify-center">
                                <FiUserCheck size={35} />
                            </div>
                            <div className="col-lg">
                                <p>{totalRejectedApplicants}</p>
                                <p>Total Rejected Applicants</p>
                            </div>
                            <div className="col-lg flex items-center justify-center">
                                <FiUserX size={35} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 py-3 px-2">
                    <div className="r6 card px-2">
                        <div className="card-body row">
                            <div className="col-lg">
                                <p>{applyRate.toFixed(2)}%</p>
                                <p>Apply rate</p>
                            </div>
                            <div className="col-lg flex items-center justify-center">
                                <CiFilter size={35} />
                            </div>
                        </div>
                    </div>
                </div>
                <MediaQuery query="(min-width: 787px)">
                <div className="card-footer flex justify-center items-center">
                <div style={{ width: '700px', height: '500px', marginLeft:'80' }} className='bar flex '>
                                <Bar data={graphData} width={200} height={200} />
                            <div className='content innline-block' style={{ width: '400px', height: '500px', padding:'25px', marginTop:'50px'}}>
                            <h3><b>Data Summary</b></h3>
                            <br/>
                            <ul className='cont'>
                                {/* Bar chart data */}
                                <li style={{color:'#2471a3'}}><b>Total Jobs Posted: {totalJobsPosted}</b></li>
                                <li style={{color:'#f39c12'}}><b>Total Clicks: {totalClicks}</b></li>
                                <li style={{color:'#aab7b8'}}><b>Total Views: {totalViews}</b></li>
                                <li style={{color:'#DE3163'}}><b>Total Applicants: {totalApplicants}</b></li>
                                <li style={{color:'#48c9b0'}}><b>Total Selected Applicants: {totalSelectedApplicants}</b></li>
                                <li style={{color:'#f44336'}}><b>Total Rejected Applicants: {totalRejectedApplicants}</b></li>
                            </ul></div>

                            </div>
                            {/* Pie chart with reduced size */}
                            <div style={{ width: '700px', height: '500px', marginLeft:'20%'}}>
                                <div>
                                <Pie data={pieData} width={300} height={300} className='pie flex' /><br/>
                                <h3><b>Pie Chart - Applicants Distribution</b></h3><br/>
                            <ul>
                            <li style={{color:'#2471a3'}}><b>Total Jobs Posted: {totalJobsPosted}</b></li>
                                <li style={{color:'#f39c12'}}><b>Total Clicks: {totalClicks}</b></li>
                                <li style={{color:'#aab7b8'}}><b>Total Views: {totalViews}</b></li>
                                <li style={{color:'#DE3163'}}><b>Total Applicants: {totalApplicants}</b></li>
                                <li style={{color:'#48c9b0'}}><b>Total Selected Applicants: {totalSelectedApplicants}</b></li>
                                <li style={{color:'#f44336'}}><b>Total Rejected Applicants: {totalRejectedApplicants}</b></li>
                            </ul>
                            </div>
                            </div>
                        </div></MediaQuery>
                        <MediaQuery query="(max-width: 786px)">
                        <div className="card-footer block justify-center items-center">
                        <div style={{ width: '250px', height: '500px', marginLeft:'80' }} className='bar block '>
                                <Bar data={graphData} width={200} height={200} />
                            <div className='content innline-block' style={{ width: '400px', height: '500px', padding:'25px'}}>
                            <h3><b>Data Summary</b></h3>
                            <br/>
                            <ul className='cont'>
                                {/* Bar chart data */}
                                <li style={{color:'#2471a3'}}><b>Total Jobs Posted: {totalJobsPosted}</b></li>
                                <li style={{color:'#f39c12'}}><b>Total Clicks: {totalClicks}</b></li>
                                <li style={{color:'#aab7b8'}}><b>Total Views: {totalViews}</b></li>
                                <li style={{color:'#DE3163'}}><b>Total Applicants: {totalApplicants}</b></li>
                                <li style={{color:'#48c9b0'}}><b>Total Selected Applicants: {totalSelectedApplicants}</b></li>
                                <li style={{color:'#f44336'}}><b>Total Rejected Applicants: {totalRejectedApplicants}</b></li>
                            </ul></div>

                            </div>
                            {/* Pie chart with reduced size */}
                            <div style={{ width: '250px', height: '500px', marginLeft:'20%', marginTop:'50%'}}>
                                <div>
                                <Pie data={pieData} width={200} height={200} className='pie block' /><br/>
                                <h3 style={{textAlign:'center', marginRight:'5px'}}><b>Pie Chart - Applicants Distribution</b></h3><br/>
                            <ul>
                            <li style={{color:'#2471a3'}}><b>Total Jobs Posted: {totalJobsPosted}</b></li>
                                <li style={{color:'#f39c12'}}><b>Total Clicks: {totalClicks}</b></li>
                                <li style={{color:'#aab7b8'}}><b>Total Views: {totalViews}</b></li>
                                <li style={{color:'#DE3163'}}><b>Total Applicants: {totalApplicants}</b></li>
                                <li style={{color:'#48c9b0'}}><b>Total Selected Applicants: {totalSelectedApplicants}</b></li>
                                <li style={{color:'#f44336'}}><b>Total Rejected Applicants: {totalRejectedApplicants}</b></li>
                            </ul>
                            </div>
                            </div>
                        </div></MediaQuery>
            </div>
        </div>
    )
}

export default EmployerDash