import React, { useState } from "react";
import { SelectInput, TextInput } from "../../../components";

const FormStepOne = ({nextStep, handleInputChange, formData }) => {
  const [formErrors, setFormErrors] = useState({
    role: "",
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
  });

  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordToggle, setPasswordToggle] = useState(false);

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!formData.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    }

    if (!formData.username.trim()) {
      errors.username = "username is required";
      isValid = false;
    }

    if (!formData.role) {
      errors.role = "Please select an option";
      isValid = false;
    }

    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

    if (!formData.password.trim()) {
      errors.password = "Password is required";
      isValid = false;
    } else if (!passwordPattern.test(formData.password)) {
      errors.password = "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character";
      isValid = false;
    }

    if (formData.password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handlePasswordToggle = () => {
    setPasswordToggle(!passwordToggle);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Store the email in sessionStorage
      nextStep();
    }
  };
  const options = [
    { value: 'user', label: 'looking for job' },
    { value: 'admin', label: 'hire employees' },
  ];

  return (
    <>
    <form>
      <div className="form-group mb-3">
        <label htmlFor="role" className="h6 mb-1">
          What are you looking for
        </label>
        <SelectInput
          options={options}
          value={formData.role}
          onChange={handleInputChange("role")}
        />
        {formErrors.role && <p className="text-danger text-sm">{formErrors.role}</p>}
      </div>
      <div className="form-group mb-3">
        <label htmlFor="username" className="h6 mb-1">
          Username
        </label>
        <TextInput
          type="text"
          placeholder="Enter your username"
          name="username"
          value={formData.username}
          onChange={handleInputChange("username")}
        />
        {formErrors.username && <p className="text-danger text-sm">{formErrors.username}</p>}
      </div>
      <div className="form-group mb-3">
        <label htmlFor="email" className="h6 mb-1">
          Email <sup className="text-danger">*</sup>
        </label>
        <TextInput
          type="text"
          placeholder="e.g. abc@example.com"
          name="email"
          value={formData.email}
          onChange={handleInputChange("email")}
        />
        {formErrors.email && <p className="text-danger text-sm">{formErrors.email}</p>}
      </div>
      <div className="form-group mb-3">
        <label htmlFor="password" className="h6 mb-1">
          Password <sup className="text-danger">*</sup>
        </label>
        <TextInput
          type={passwordToggle ? "text" : "password"}
          placeholder="enter password"
          name="password"
          value={formData.password}
          onChange={handleInputChange("password")}
        />
        {formErrors.password && <p className="text-danger text-sm">{formErrors.password}</p>}
      </div>
      <div className="form-group mb-3">
        <label htmlFor="confirmPassword" className="h6 mb-1">
          Confirm Password <sup className="text-danger">*</sup>
        </label>
        <TextInput
          type={passwordToggle ? "text" : "password"}
          placeholder="confirm password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {formErrors.confirmPassword && <p className="text-danger text-sm">{formErrors.confirmPassword}</p>}
      </div>
      <div className="form-group mt-2 flex items-center gap-2">
        <input className="border" type="checkbox" checked={passwordToggle} onChange={handlePasswordToggle} id="flexCheckDefault" />
        <label className="" htmlFor="flexCheckDefault">
          Show password
        </label>
      </div>
      <div className="form-group pt-3 flex gap-3 items-center">
        <button onClick={handleFormSubmit} className="btn bg-prime text-light">
          Submit
        </button>
      </div>
      </form>
    </>
  );
};

export default FormStepOne;
