import React, { useState, useRef } from 'react';
import { convertTimeIntoMMYYYY } from '../../../utils/dateFilter/DateFilter';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import UpdateInput from '../../../components/modal/UpdateInput';
import { ModalBox } from '../../../components';
import axios from 'axios';
import  {resumeUpload}  from '../../../api/Api';
 
const ProfilePage = ({ data }) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentField, setCurrentField] = useState('');
  const [currentValue, setCurrentValue] = useState('');
 
  const handleEditClick = (field, value) => {
    setCurrentField(field);
    setCurrentValue(value);
    setOpenModal(true);
  };
 
  const [errorMessage, setErrorMessage] = useState('');  // State for error message
  const [successMessage, setSuccessMessage] = useState('');  // State for success message
  const [file, setFile] = useState(null);
  const fileInput = useRef(null);
  const [Data,setData] = useState(data); 
 
  // Handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
 
    // Clear previous messages
    setErrorMessage('');
    setSuccessMessage('');
 
    if (!file) {
      setErrorMessage('Please select a file to upload.');
      return;
    }
 
    // Create FormData object to send file data
    const formData = new FormData();
    formData.append('file', file);
 
    try {
      // Send POST request to the server
      const response = await axios.post(resumeUpload, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
 
      // Handle successful response
      setSuccessMessage('File uploaded successfully.');
      console.log(response.data);
    } catch (error) {
      // Check if the error response exists
      if (error.response) {
        // Check if there's a server error message
        if (error.response.data && error.response.data.message) {
          setErrorMessage(error.response.data.message); // Set error message from the backend
        } else {
          setErrorMessage(`Error: ${error.response.status} - ${error.response.statusText}`); // Generic error message with status
        }
      } else if (error.request) {
        // The request was made but no response was received
        setErrorMessage('No response from the server. Please try again later.');
      } else {
        // Something happened in setting up the request
        setErrorMessage('Error in setting up the request: ' + error.message);
      }
    }
  };
 
 
 
   const handleUpdate = (updatedData) => {
    window.location.reload();
    // Update the local profile data with the new value
    setData((prevData) => ({
      ...prevData,
      [updatedData.key]: updatedData.value,
    }));
    setOpenModal(false);
  };

  const closeModal = () => {
    setOpenModal(false);
  };
 
  return (
    <>
      <div className='container-fluid bg-[#F4F2EE]'>
        <div className="container-sm bg-light">
          <div className="row py-4 bg-light">
            <div className="col-lg-2">
              <div className="col py-3">
                <img src="https://i.pravatar.cc/300" alt="" className='w-24 rounded-circle' />
              </div>
            </div>
            <div className="col-lg">
              <h3 className='text-xl font-semibold capitalize'>{data?.firstName} {data?.lastName}</h3>
              <ul className="nav gap-2">
                <li className='nav-item'>
                  {data?.mobile} |
                </li>
                <li className='nav-item'>
                  {data?.email}
                </li>
              </ul>
              <ul className="nav gap-2">
                <li className='nav-item'>
                  {data?.address?.street} |
                </li>
                <li className='nav-item'>
                  {data?.address?.city}
                </li>
                <li className='nav-item'>
                  {data?.address?.state} |
                </li>
                <li className='nav-item'>
                  {data?.address?.pincode}
                </li>
              </ul>
              <ul className="nav gap-2">
                <li className='nav-item'>
                  {data?.experienceInYear} year's of experience |
                </li>
              </ul>
              <ul className="nav items-center gap-2 pt-4">
                <li className='nav-item'>
                  <a href={data?.links?.github} className='text-2xl' target='_blank' rel="noopener noreferrer"><FaGithub /></a>
                </li>
                <li className='nav-item'>
                  <a href={data?.links?.linkedin} className='text-2xl text-[#0962C0]' target='_blank' rel="noopener noreferrer"><FaLinkedin /></a>
                </li>
                <li className='nav-item'>
                  <a href={data?.links?.twitter} className='text-2xl text-gray-500' target='_blank' rel="noopener noreferrer"><FaXTwitter /></a>
                </li>
              </ul>
            </div>
          </div>
          <p className='hr bg-slate-200'></p>
          <div className="row py-3">
            <div className="col-lg">
              <h4 className='h3'>Basic Detail</h4>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <p>
                    <label htmlFor="" className='h6 text-secondary capitalize'>First Name</label>
                    <Link className='text-primary ms-2' onClick={() => handleEditClick('firstName', data?.firstName)}>edit</Link>
                  </p>
                  <p className='capitalize'>{data?.firstName}</p>
                </div>
                <div className="col-md-6 mb-3">
                  <p>
                    <label htmlFor="" className='h6 text-secondary capitalize'>Last Name</label>
                    <Link className='text-primary ms-2' onClick={() => handleEditClick('lastName', data?.lastName)}>edit</Link>
                  </p>
                  <p className='capitalize'>{data?.lastName}</p>
                </div>
                <div className="col-md-6 mb-3">
                  <p>
                    <label htmlFor="" className='h6 text-secondary capitalize'>Email</label>
                    <Link className='text-primary ms-2' onClick={() => handleEditClick('email', data?.email)}>edit</Link>
                  </p>
                  <p className='capitalize'>{data?.email}</p>
                </div>
                <div className="col-md-6 mb-3">
                  <p>
                    <label htmlFor="" className='h6 text-secondary capitalize'>Mobile</label>
                    <Link className='text-primary ms-2' onClick={() => handleEditClick('mobile', data?.mobile)}>edit</Link>
                  </p>
                  <p className='capitalize'>{data?.mobile}</p>
                </div>
                <div className="col-md-6 mb-3">
                  <p>
                    <label htmlFor="" className='h6 text-secondary capitalize'>Website</label>
                    <Link className='text-primary ms-2' onClick={() => handleEditClick('website', data?.website)}>edit</Link>
                  </p>
                  <p className='capitalize'>{data?.website}</p>
                </div>
              </div>
            </div>
          </div>
          <p className='hr bg-slate-200'></p>
          <div className="row mb-3">
            <div className="col-lg py-3">
              <h4 className='h3'>Experience</h4>
              {data?.experiences?.map((exp, idx) => (
                <div key={idx} className="row py-2">
                  <div className="col-lg">
                    <p>
                  <label htmlFor="" className='h6 text-secondary capitalize'>Title</label>
                    <h4 className='h6'>{exp?.title}</h4></p><br/>
                    <p>
                    <label htmlFor="" className='h6 text-secondary capitalize'>Company Name</label><br/>
                    <span>{exp?.company}</span></p><br/>
                    <p>
                    <label htmlFor="" className='h6 text-secondary capitalize'>Start Date</label>
                    <ul className="nav">
                      <li className="nav-item">
                        {convertTimeIntoMMYYYY(exp?.startDate)}
                      </li>
                    </ul>
                    </p>
                  </div>
 
                  <div className="col-lg "><br />
                    <form onSubmit={handleSubmit} className='up text-dark text-14'><b>Upload Resume</b>
                      <input
                        type="file"
                        name="file"
                        placeholder='supported .doc,.docx,.pdf file'
                        className="form-control w-50 mt-2 rounded"
                        ref={fileInput}
                        onChange={handleFileChange} />
                      <span className="place mt-3 text-center justify-center text-orange-700 text-12"><b>Supported formats: .doc, .docx, .pdf</b></span><br />
                      <button type="submite" className='capital mt-2 bg-prime rounded w-25 h-8 text-white'>Upload</button>
                    </form>
                    {/* Display error message */}
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    {/* Display success message */}
                    {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                  </div>
                </div>
              ))}
            </div>
          </div>
           <p className='hr bg-slate-200'></p>
          <div className="row">
            <div className="col-lg py-3">
              <h4 className='h3'>Education</h4>
              {data?.education?.map((exp, idx) => (
                <div key={idx} className="row py-2">
                  <div className="col-lg">
                    <p>
                  <label htmlFor="" className='h6 text-secondary capitalize'>Title</label>
                    <h4 className='h6'>{exp?.title}</h4></p>
                    <p><br/>
                    <label htmlFor="" className='h6 text-secondary capitalize'>University</label><br/>
                    <span>{exp?.university}</span></p><br/>
                    
                      <ul className="nav inline-block">
                      <p><label htmlFor="" className='h6 text-secondary capitalize'>Start Date</label>
                      <li className="nav-item">
                        {convertTimeIntoMMYYYY(exp?.startDate)}
                      </li></p><br/>
                      <p><label htmlFor="" className='h6 text-secondary capitalize'>End Date</label>
                      <li className="nav-item">
                        {convertTimeIntoMMYYYY(exp?.endDate)}
                      </li></p>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <ModalBox closeModal={closeModal}>
          <UpdateInput
            title={currentValue}
            field={currentField}
            onUpdate={handleUpdate}
          />
        </ModalBox>
      )}
    </>
  );
};
 
export default ProfilePage;