import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context";
import coverImg from '../../assets/logo.jpeg';
import { TextInput } from "../../components";

const Login = () => {
  const { AuthenticateUser, token,loading } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [forgotPassword, setForgotPassword] = useState(false)
  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
  });
  const [formError, setFormError] = useState({
    email: false,
    password: false,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordToggle, setPasswordToggle] = useState(false);
  const validateFormInput = () => {
    const errors = {};
    if (!loginCredentials.email.trim()) {
      errors.email = true;
    }
    if (!loginCredentials.password.trim()) {
      errors.password = true;
    }
    setFormError(errors);
    return Object.keys(errors).length === 0;
  };
  const handlePasswordToggle = () => {
    setPasswordToggle(!passwordToggle);
  };


  const handleInputChange = (e) => {
    setLoginCredentials({
      ...loginCredentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFormInput()) {
      // console.log("this is ", loginCredentials)
      try {
        await AuthenticateUser(loginCredentials);
        navigate("/")
      } catch (error) {
        setErrorMessage(error.message);
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
      }
    }
  };

  const handleBackToLogin = () => {
    setForgotPassword(false);
  }

  return (
    <div className="container-fluid">
      <div className="container-sm py-3">
        <div className="row justify-center min-h-[100vh] items-center">
          <div className="col-lg-6 bg-light shadow py-6">
            <h4 className="text-center h2 text-prime">Login here</h4>
            {errorMessage && <p className="text-danger pt-4">{errorMessage}</p>}
            <form onSubmit={handleSubmit} className="my-4">
              <div className="form-group mb-3">
                <label htmlFor="" className="h6 mb-1">Username or Email <sup className="text-danger">*</sup></label>
                <TextInput type="email" name="email" value={loginCredentials.email} onChange={handleInputChange} placeholder="Username or Email" />
                {formError.email && <p className="text-danger">Email is required</p>}
              </div>
              <div className="form-group mb-3">
                <label htmlFor="" className="h6 mb-1"> Password <sup className="text-danger">*</sup></label>
                <TextInput type={passwordToggle ? "text" : "password"} className="form-control" name="password" value={loginCredentials.password} onChange={handleInputChange} placeholder="Password" />
                {formError.password && <p className="text-danger">Password is required</p>}
                <div className="form-group mt-2 flex items-center gap-2">
                  <input className="border" type="checkbox" value={loginCredentials.password} checked={passwordToggle} onChange={handlePasswordToggle} id="flexCheckDefault" />
                  <label className="" htmlFor="flexCheckDefault">
                    show password
                  </label>
                </div>
              </div>
              <div className="form-group mb-3 flex gap-3 items-end">
                <button className="btn bg-primary text-light" disabled={loading}>
                  {loading ? "Please wait..." : "Login"}
                </button>
              </div>
              <div className="form-group mb-3 ">
                <Link to='/forgot-password' className="nav-link">Dont't remember your password ? <span className="text-primary">Forgot your password</span></Link>
                <p className="">Don't have an account?{" "}
                  <Link to="/register" className="text-prime font-semibold">Create an account</Link>
                </p>
                <Link className="nav-link text-primary">Need help in siging in ?</Link>
              </div>
              <div className='my-3'>
                <button onClick={() => navigate('/')} className='btn btn-primary rounded w-18 justify-center items-center'>Back to home</button>
            </div>
            </form>
          </div>
          <div className="box flex flex-col gap-2 items-center justify-center">
            <img src={coverImg} alt="" className="img-fluid w-16" />
            <p className="text-center text-secondary font-semibold">All rights reserved by &#169; Career Techguru&#8482; </p>
          </div>
        </div>
        
      </div>
    </div>
    
  );
};

export default Login;
