import { createContext, useState } from "react";

export const SearchContext = createContext();

const SearchContextProvider = ({children}) => {
    const [searchResult, setSearchResult] = useState([]);

    return (
        <SearchContext.Provider value={{searchResult, setSearchResult}}>
            {children}
        </SearchContext.Provider>
    )
}

export default SearchContextProvider;