import React, { useEffect, useState } from 'react'
 
import {useLocation, useNavigate} from 'react-router-dom'
 
import OTPInput, { ResendOTP } from "otp-input-react";
 
import { useAuthContext } from '../../../context';
 
 
const EmailVerify = () => {
 
  const {token} = useAuthContext();
 
  const {verifyUserEmail} = useAuthContext();
 
  const [OTP, setOTP] = useState("");
 
  const location = useLocation();
 
  const navigate = useNavigate();
 
  const handleOTPChange = (otp) => {
 
    setOTP(otp);
 
  };
 
 
  const handleVerifyClick = async () => {
    try {
      const result = await verifyUserEmail(OTP); // Verify OTP
      console.log("Verification result:", result);
 
      if (result.token) {
        // Navigate to home page after successful verification
        navigate("/");
 
      } else {
        console.error("Verification failed:", result.message);
      }
    } catch (error) {
      console.error("Error verifying email:", error);
    }
  };
 
 
  return (
<div className='container-fluid-sm mt-20' >
<div className='form-group bg-slate-200 py-3 flex flex-col items-center gap-3'>
<h2 className='font-semibold h2 text-[#0C4DB0]'>Verify OTP received on email</h2>
<OTPInput value={OTP} OTPLength={6} onChange={handleOTPChange} />
<button className='btn-main bg-[#0C4DB0] w-20 h-8 text-light' onClick={handleVerifyClick}>Verify</button>
<ResendOTP autoFocus otpType="number" onResendClick={() => console.log("Resend clicked")} disabled={false} secure />
</div>
</div>
  )
 
}
 
export default EmailVerify