import React, { useEffect, useState } from 'react';
import { useJobContext } from '../context';
import { useParams } from 'react-router-dom';
import { VscLinkExternal } from "react-icons/vsc";
import { IoShareSocialSharp } from 'react-icons/io5';
import DOMPurify from "dompurify";
import ConfirmBox from '../components/modal/ConfirmBox';
import { CircularLoader } from '../components';

const JobDetailPage = () => {
    const { getApplicationsByJobId, getAllApplications } = useJobContext();
    const { title } = useParams();
    const [jobData, setJobData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isConfirmBoxVisible, setConfirmBoxVisible] = useState(false);

    const fetchJobDetails = async () => {
        try {
            const allApplications = await getAllApplications();
            const job = allApplications.find(app => app.title === title);
            if (job) {
                const response = await getApplicationsByJobId(job._id);
                setJobData(response?.data);
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchJobDetails();
    }, [title]);

    const handleConfirm = () => {
        window.open(jobData?.jobUrl, '_blank', 'noopener,noreferrer');
        setConfirmBoxVisible(false);
    };

    if (loading) {
        return <CircularLoader />;
    }

    if (!jobData) {
        return <p>Job not found</p>;
    }

    return (
        <>
            {isConfirmBoxVisible && (
                <ConfirmBox
                    message={`You will be redirected to ${jobData?.companyName} careers. Are you sure you want to continue?`}
                    onConfirm={handleConfirm}
                    onCancel={() => setConfirmBoxVisible(false)}
                />
            )}
            <div className="container-fluid">
                <div className="container-sm">
                    <div className="row py-3">
                        <div className="col-lg">
                            <div className="card">
                                <div className="card-body">
                                    <div className="col-1 my-3">
                                        <img src={jobData?.profilePicture} alt="" className='img-thumbnail' />
                                    </div>
                                    <p className='flex items-center gap-2'>
                                        <span className='text-sm text-secondary'>{jobData?.companyName}</span>
                                        <a href={jobData?.companyWebsite ?? '#'} className='nav-link' target='_blank' rel='noopener noreferrer'>
                                            <VscLinkExternal />
                                        </a>
                                    </p>
                                    <h2 className="card-title h5">{jobData?.title}</h2>
                                    <ul className="nav items-center gap-3 py-2">
                                        <li className="nav-item bg-slate-200 p-2 rounded text-sm font-semibold">
                                            {jobData?.jobType}
                                        </li>
                                        <li className="nav-item p-2 bg-slate-200 rounded text-sm font-semibold">
                                            {jobData?.workType}
                                        </li>
                                        <li className="nav-item p-2 bg-slate-200 rounded text-sm font-semibold">
                                            {jobData?.openings} Openings
                                        </li>
                                        <li className="nav-item flex bg-slate-200 items-center gap-2 p-2 rounded text-sm font-semibold">
                                            <IoShareSocialSharp />
                                            <span>Share job</span>
                                        </li>
                                    </ul>
                                    <div className="row">
                                        <div className="col-lg">
                                            <ul className="nav flex-col gap-2 py-2">
                                                <li className="nav-item flex items-center gap-3">
                                                    <b>Experience</b> {jobData?.experience}
                                                </li>
                                                <li className="nav-item flex items-center gap-3">
                                                    <b>Location</b>{jobData?.jobLocation}
                                                </li>
                                                <li className="nav-item flex items-center gap-3">
                                                    <b>Min-Qualification</b>{jobData?.qualification}
                                                </li>
                                                <li className="nav-item flex items-center gap-3">
                                                    <b>Salary Range</b>{jobData?.salaryRange}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg flex items-end justify-end">
                                            <div className="box">
                                                {jobData?.isReferenceJob
                                                    ? <button onClick={() => setConfirmBoxVisible(true)} className='btn bg-prime text-light'>Apply now</button>
                                                    : <button className='btn bg-prime text-light'>Apply now</button>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col-lg">
                            <div className="card py-3 mb-3">
                                <div className="card-body">
                                    <h2 className='h3'>Job Highlights</h2>
                                    <p>{jobData?.shortDescription}</p>
                                </div>
                            </div>
                            <div className="card py-3">
                                <div className="card-body">
                                    <h2 className='h3'>Job Highlights</h2>
                                    <p className="text-[14px] text-secondary text-justify" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(jobData?.description) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default JobDetailPage;
