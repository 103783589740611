import React, { useEffect, useState, useRef} from 'react';
import { SelectInput, TextInput } from '../../../components';
import { GetCountries } from 'react-country-state-city';
import { useAuthContext } from '../../../context';
import axios from 'axios';
import  {resumeUpload}  from '../../../api/Api';

const FormStep2 = ({ nextStep, prevStep, handleInputChange, formData, handleSubmit }) => {
  const { loading } = useAuthContext();
  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
  });
  const [countriesList, setCountriesList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');  // State for error message
  const [successMessage, setSuccessMessage] = useState('');  // State for success message
  const [file, setFile] = useState(null);
  const fileInput = useRef(null); 

  useEffect(() => {
    // Fetch countries
    const fetchCountries = async () => {
      const countries = await GetCountries();
      // Format countries into options array
      const countryOptions = countries.map(country => ({ value: country.isoCode, label: country.name }));
      setCountriesList(countryOptions);
    };
    fetchCountries();
  }, []);

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!formData.firstName.trim()) {
      errors.firstName = "First Name is required";
      isValid = false;
    }

    if (!formData.lastName.trim()) {
      errors.lastName = "Last Name is required";
      isValid = false;
    }

    if (!formData.mobile.trim()) {
      errors.mobile = "Mobile number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      errors.mobile = "Mobile number must be 10 digits and no alphabets";
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleSubmit();
    }
  };

  return (
    <>
      <div className="form-group mb-3">
        <label htmlFor="country" className="h6 mb-1">
          Country
        </label>
        <SelectInput
          options={countriesList} // Pass the countries list as options
          value={formData.country}
          onChange={handleInputChange("country")}
        />
        {formErrors.country && <p className="text-danger text-sm">{formErrors.country}</p>}
      </div>
      <div className="form-group mb-3">
        <label htmlFor="firstName" className="h6 mb-1">
          First Name <sup className="text-danger">*</sup>
        </label>
        <TextInput
          type="text"
          placeholder="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleInputChange("firstName")}
        />
        {formErrors.firstName && <p className="text-danger text-sm">{formErrors.firstName}</p>}
      </div>
      <div className="form-group mb-3">
        <label htmlFor="lastName" className="h6 mb-1">
          Last Name <sup className="text-danger">*</sup>
        </label>
        <TextInput
          type="text"
          placeholder="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleInputChange("lastName")}
        />
        {formErrors.lastName && <p className="text-danger text-sm">{formErrors.lastName}</p>}
      </div>
      <div className="form-group mb-3">
        <label htmlFor="mobile" className="h6 mb-1">
          Mobile
        </label>
        <TextInput
          type="text"
          placeholder="Enter mobile number"
          name="mobile"
          value={formData.mobile}
          onChange={handleInputChange("mobile")}
        />
        {formErrors.mobile && <p className="text-danger text-sm">{formErrors.mobile}</p>}
      </div>
      <div className="form-group pt-3 flex gap-3 items-center">
        <button
          onClick={handleFormSubmit}
          className="btn bg-prime text-light"
          disabled={loading}
        >
          Submit
        </button>
        <button onClick={prevStep} className="btn btn-secondary">
          Back
        </button>
      </div>
    </>
  );
};

export default FormStep2;
