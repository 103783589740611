import React from 'react';

const SelectInput = ({ options, value, onChange, ...props }) => {
  return (
    <select
      value={value}
      onChange={onChange}
      {...props}
      className='custom-select w-100 outline-none px-4 py-2 bg-slate-100 border-b-2 border-[#161E7C]'
    >
        <option defaultValue="">choose options</option>
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SelectInput;
