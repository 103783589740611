import React from 'react'

const TextInput = ({type = "text", placeholder, value, onchange, ...props}) => {
  return (
    <input
        type={type}
        placeholder={placeholder}
        value={value}
        onchange={onchange}
        {...props}
        className='form-control'
    />
  )
}

export default TextInput