import React from 'react'

const Footer = () => {
  return (
    <footer className='container-fluid-sm'>
      <div className="container-sm1">
      </div>
        <div className="container.sm1">
            <div className="row justify-center items-center py-3">
                <div className="col-lg-6">
                    <p className="text-center text-secondary1 font-semibold1">All rights reserved by &#169; Career Techguru&#8482; </p>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer