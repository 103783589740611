import React, { useState } from "react";
import FormStepOne from "./FormStepOne";
import FormStepTwo from "./FormStepTwo";
import { useAuthContext } from "../../../context";
import { Link, useNavigate } from "react-router-dom";
import coverimg from '../../../assets/logo.jpeg'
import EmailVerify from "./EmailVerify";
import { CircularLoader } from "../../../components";
const Signup = () => {
  const [step, setStep] = useState(1);
  const { RegisterUser, loading } = useAuthContext();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    username : "",
    country : "",
    password: "",
    role: "",
  });

  const navigate = useNavigate();

  const nextStep = () => setStep((prevStep) => prevStep + 1);
  const prevStep = () => setStep((prevStep) => prevStep - 1);

  const handleInputData = (input) => (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const userData = await RegisterUser(formData);
      if (userData) {
        nextStep(); // or navigate('/');
      } else {
        console.error("Registration failed: Unexpected response from server");
      }
    } catch (error) {
      console.error("Error during registration:", error);
    }
  };
  if(loading) { 
    return <CircularLoader />;
  }
  return (
    <div className="container-fluid min-h-[100vh]">
      <div className="container-sm py-3">
        <div className="row items-center justify-center py-4">
          <div className="col-lg">
          <div className="box flex flex-col gap-2 items-center justify-center">
            <img src={coverimg} alt="" className="img-fluid w-48 me-12" />
            <p className="text-center text-secondary font-semibold me-12">All rights reserved by &#169; Career Techguru&#8482; </p>
          </div>
          </div><br/>
          <div className="col-lg bg-white py-6 justify-center items-center">
          <h4 className="text-[#0F3CA7] h2">Register your account</h4>
          <Link to='/login' className="nav-link">Already have an account ? <span className="text-primary">Login here</span></Link>
            {step === 1 && (
              <FormStepOne
                nextStep={nextStep}
                handleInputChange={handleInputData}
                formData={formData}
                handleSubmit={handleSubmit}
              />
            )}
            {step === 2 && (
              <FormStepTwo
                nextStep={nextStep}
                prevStep={prevStep}
                handleInputChange={handleInputData}
                handleSubmit={handleSubmit}
                formData={formData}
              />
            )}
            {step === 3 && (
              <EmailVerify
                nextStep={nextStep}
                prevStep={prevStep}
                handleInputChange={handleInputData}
                handleSubmit={handleSubmit}
                formData={formData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
