import React, { useEffect, useState } from 'react'
import { Pagination, Search } from '../components'
import { JobCard, JobCardTwo } from '../shared'
import { useJobContext, useSearchContext, useSectionContext } from '../context'
import { sortDataByDate } from '../utils/filters/Filters';
import {useNavigate, useSearchParams } from "react-router-dom";
 
const AllJobs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { searchResult } = useSearchContext();
  const { getAllSections } = useSectionContext();
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const itemsPerPage = 20;
 
  const getApplicationCategory = async() => {
    try {
      const result = await getAllSections();
      setSections(result?.data)
    } catch (error) {
      console.log(error)
    }
  }
 
  const handleBackClick = () => {
    // Clear search parameters
    setSearchParams(new URLSearchParams()); // This clears the query parameters
 
    window.location.href = '/view-jobs';
  };
 
 
  <button onClick={handleBackClick} className='btn bg-prime text-light'>Back</button>
 
 
  const indexOfLastJob = currentPage * itemsPerPage;
  const indexOfFirstJob = indexOfLastJob - itemsPerPage;
  const currentJobs = searchResult.slice(indexOfFirstJob, indexOfLastJob);
  const sortedJobs = sortDataByDate(currentJobs, 'createdAt')
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const navigate = useNavigate();
 
  useEffect(() => {
    getApplicationCategory();
  }, []);
  if (!searchResult || searchResult.length === 0) {
    return (
      <div className='min-h-[80vh] container-fluid'>
        <div className="container-sm py-4">
          <div className="row py-4">
            <div className="col-lg">
              <h3 className='h3 text-prime my-3'>Find jobs</h3>
              <Search categories={sections} />
            </div>
          </div>
          <h3 className="h3 text-prime">No Jobs Found</h3>
          <button onClick={handleBackClick} className='btn bg-prime text-light'>Back</button>
        </div>
      </div>
    )
  }
  return (
    <>
      <div className="container-fluid">
        <div className="container-sm">
          <div className="row py-4">
            <div className="col-lg">
              <h3 className='h3 text-prime my-3'>Find jobs</h3>
              <Search categories={sections} />
            </div>
          </div>
        </div>
        <div className="container-fluid bg-light mb-3 py-3">
          <div className="container-sm flex justify-between items-center">
            <h3 className='h5 font-semibold text-prime'>Result - Total {searchResult?.length} jobs found</h3>
            <Pagination
              totalItems={searchResult.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={paginate}
            />
          </div>
          <div className="row bg-white py-3">
            <div className="col-lg-3">
             
            </div>
            <div className="col-lg-6">
              <h3></h3>
              <JobCardTwo data={sortedJobs} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
 
export default AllJobs