import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logoImg from '../../assets/logo.jpeg';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { useAuthContext } from "../../context";
import { confirmAlert } from "react-confirm-alert";

const Navbar = () => {
  const { logoutUser } = useAuthContext();
  const userRole = sessionStorage.getItem('role');
  const navigate = useNavigate();
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const defaultNavItems = [
    { id: 1, text: <strong>Home</strong>, path: '/' },
    { id: 4, text: <strong>About</strong>, path: '/about-us' },
    { id: 5, text: <strong>Contact</strong>, path: '/contact-us' },
  ];

  const employeeNavItems = [
    { id: 1, text: <strong>Open Roles</strong>, path: 'view-jobs' },
    { id: 2, text: <strong>My Profile</strong>, path: 'view-profile' },
    { id: 3, text: <strong>Applied Jobs</strong>, path: 'view-applications' },
  ];

  const employerNavItems = [
    { id: 2, text: <strong>Post Job</strong>, path: 'add-job' },
    { id: 3, text: <strong>My Profile</strong>, path: 'view-profile' },
  ];

  const navItems = userRole === 'user' 
    ? employeeNavItems 
    : userRole === 'admin' 
    ? employerNavItems 
    : defaultNavItems;

  const Logout = () => {
    confirmAlert({
      title: 'Confirm to logout',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => logoutUser()
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  return (
    <>
      <div className='container-fluid bg-white sticky-top'>
        <div className="container flex justify-between items-center py-2">
          <Link to="/" className="flex items-center gap-2">
            <img src={logoImg} alt="Logo" width={40} />
            <span className="uppercase font-bold text-prime text-xl"><strong>career techguru</strong></span>
          </Link>
          {/* Desktop Navigation */}
          <ul className='hidden md:flex gap-5 items-center'>
            {navItems.map(item => (
              <li key={item.id} className='nav-item'>
                <Link to={item.path} className="nav-link">{item.text}</Link>
              </li>
            ))}
            <li className="nav-item">
              {userRole ? (
                <button onClick={Logout} className="btn bg-prime text-light">Logout</button>
              ) : (
                <button onClick={() => navigate("/login")} className="btn bg-prime text-light">Login</button>
              )}
            </li>
          </ul>
          {/* Mobile Navigation Icon */}
          <div onClick={handleNav} className='block md:hidden'>
            {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
          </div>
          {/* Mobile Navigation Menu */}
          <ul
            className={
              nav
                ? 'fixed md:hidden nav flex-col py-6 left-0 top-0 w-[60%] h-full border-r border-r-gray-400 bg-light ease-in-out duration-500'
                : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]'
            }
          >
            <li className="py-3 nav-item">
              <Link className="nav-link" to="/">
                <img src={logoImg} alt="Logo" className="w-36" />
              </Link>
            </li>
            {/* Mobile Navigation Items */}
            {navItems.map(item => (
              <React.Fragment key={item.id}>
                <li className='nav-item'>
                  <Link className="nav-link text-dark" to={item.path} onClick={() => setNav(false)}>{item.text}</Link>
                </li>
                <p className="hr bg-secondary" key={`divider-${item.id}`}></p>
              </React.Fragment>
            ))}
            <br/>
           <li className="nav-item ms-2.5">
              {userRole ? (
                <button onClick={Logout} className="btn bg-prime text-light">Logout</button>
              ) : (
                <button onClick={() => navigate("/login")} className="btn bg-prime text-light">Login</button>
              )}
            </li>
          </ul>
        </div>
        <p className="hr bg-slate-200"></p>
      </div>
    </>
  );
};

export default Navbar;
