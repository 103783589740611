
 
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useJobContext, useSearchContext } from '../../context';
 
 
const Search = ({ categories }) => {
  const { setSearchResult } = useSearchContext();
  const { searchJobQuery, getJobSingleDetail } = useJobContext();
 
  const [searchParams, setSearchParams] = useSearchParams();
  const [locations, setLocations] = useState([]);
  const [category, setCategory] = useState(searchParams.get('category') || '');
  const [location, setLocation] = useState(searchParams.get('jobLocation') || '');
  const [experience, setExperience] = useState(searchParams.get('experience') || '');
  const navigate = useNavigate();
  const fetchJobLocations = async () => {
    try {
      const result = await getJobSingleDetail(); // Pass object with key property
      console.log("this is list", result?.data);
      setLocations(result?.data)
    } catch (error) {
      console.error('Error fetching job locations:', error);
    }
  };
  const fetchInitialJobs = async () => {
    try {
      const result = await searchJobQuery('');
      setSearchResult(result);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchJobLocations();
    fetchInitialJobs();
  }, [searchJobQuery, setSearchResult]);
 
  useEffect(() => {
    const params = new URLSearchParams();
 
    if (category) params.append('category', category);
    if (location) params.append('jobLocation', location);
    if (experience) params.append('experience', experience);
 
    setSearchParams(params);
  }, [category, location, experience, setSearchParams]);
 
  const categoryOptions = categories?.map(category => ({
    value: category?._id,
    label: category?.title,
  }));
 
  const locationOptions = locations?.map(location => ({
    value : location,
    label : location
  }))
 
  const experienceOptions = [
    { value: 'fresher', label: 'Fresher' },
    { value: '1 - 2 years', label: '1 - 2 years' },
    { value: '3 - 5 years', label: '3 - 5 years' },
    { value: '5+ years', label: '5+ years' },
  ];
 
  const handleSearch = async () => {
    try {
      const params = new URLSearchParams();
 
      if (category) params.append('category', category);
      if (location) params.append('jobLocation', location);
      if (experience) params.append('experience', experience);
 
      const queryString = params.toString();
      const result = await searchJobQuery(queryString);
     
      setSearchResult(result);
      navigate('/view-jobs?' + queryString, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };
 
  const handleClear = () => {
    setCategory('');
    setLocation('');
    setExperience('');
    setSearchParams(new URLSearchParams()); // Clear URL search parameters
  };
 
 
  return (
    <div className='form row items-center'>
      <div className="col-lg form-group mb-3">
        <label htmlFor="category" className="h6 mb-1">Category</label>
        <Select
          options={categoryOptions}
          value={categoryOptions?.find(option => option?.value === category) || null}
          onChange={(selectedOption) => setCategory(selectedOption?.value)}
          isClearable
        />
      </div>
      <div className="col-lg form-group mb-3">
        <label htmlFor="location" className="h6 mb-1">Location</label>
        <Select
          options={locationOptions}
          value={locationOptions.find(option => option?.value === location) || null}
          onChange={(selectedOption) => setLocation(selectedOption?.value)}
          isClearable
        />
      </div>
      <div className="col-lg form-group mb-3">
        <label htmlFor="experience" className="h6 mb-1">Experience</label>
        <Select
          options={experienceOptions}
          value={experienceOptions.find(option => option?.value === experience) || null}
          onChange={(selectedOption) => setExperience(selectedOption?.value)}
          isClearable
        />
      </div>
      <div className="col-lg flex items-center gap-2">
        <button className="btn bg-prime text-light w-100" onClick={handleSearch}>Search</button>
        <button className="btn bg-secondary text-light w-100" onClick={handleClear}>Clear</button>
      </div>
    </div>
  );
};
 
export default Search;
 
 
