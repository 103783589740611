import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useAdminContext, useJobContext } from "../../../context";
import { IoLocationSharp } from "react-icons/io5";
import DOMPurify from "dompurify";
import { FaSuitcase } from "react-icons/fa";
import { truncateText } from "../../../utils/stringBuilder/Stringify";
import { IoShareSocial } from "react-icons/io5";
import { getTimeDifference } from "../../../utils/dateFilter/DateFilter";
 
const JobCardTwo = ({ data }) => {
  const { getAdminsDetailsById } = useAdminContext();
  const [companyDataMap, setCompanyDataMap] = useState({});
  const navigate = useNavigate();
 
  useEffect(() => {
    const fetchCompanyData = async () => {
      const companyData = {};
      try {
        for (const item of data) {
          if (item?.sellerId && !companyData[item.sellerId]) {
            const company = await getAdminsDetailsById(item?.sellerId, "Employer");
            companyData[item?.sellerId] = company;
          }
        }
      } catch (error) {
        console.error("Failed to fetch company details:", error);
      }
      setCompanyDataMap(companyData);
    };
 
    if (data?.length) {
      fetchCompanyData();
    }
  }, [data]);
 
  return (
    <>
      {data?.map((job, idx) => (
        <div key={idx} className="col-lg px-2 mb-3">
          <div className="card">
            <div className="card-body relative">
              <img src={job?.profilePicture} alt="" className="img-fluid h-10 mb-3" />
              <p className="flex items-center justify-between gap-3">
                {job?.companyName ? <span className="text-sm text-secondary">{job.companyName}</span> : null}
                <span className="text-sm text-secondary">{getTimeDifference(job?.createdAt)}</span>
              </p>
              <Link className="nav-link" to={`/view-jobs-detail/${job?.title}`}>
                <p className="">{truncateText(job?.title, 5)}..</p>
              </Link>
              <ul className="nav items-center gap-2 my-2">
                <li className="capitalize text-xs flex items-center text-secondary gap-1 px-2 bg-slate-200">
                  <IoLocationSharp />{job?.jobLocation}
                </li>
                <li className="capitalize text-xs flex items-center text-secondary bg-slate-200 px-2 gap-1">
                  <FaSuitcase />{job?.experience}
                </li>
              </ul>
              <div className="flex items-center gap-3 mt-4">
                <button className="flex justify-center items-center btn px-9 bg-prime text-light">Share <IoShareSocial /> </button>
                <button onClick={() => navigate(`/view-jobs-detail/${job?.title}`)} className="btn px-8 bg-prime text-light">Apply</button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
 
export default JobCardTwo;
