import React, { useEffect, useState } from 'react'
// import {featureListOne } from '../testData/StaticData'
import Marquee from "react-fast-marquee";
import { useJobContext, useSectionContext } from '../context';
import { FcOk } from "react-icons/fc";
import { IoRibbonSharp } from "react-icons/io5";
import { FaHandHoldingUsd, FaUsers } from "react-icons/fa";
import { GiBrain, GiElvenCastle, GiPuzzle } from "react-icons/gi";
import { FiMessageSquare } from "react-icons/fi";
import { MdCelebration } from 'react-icons/md';
import banner from '../assets/logo.jpeg'

const Home = () => {
//  const { sections } = useSectionContext();
  const {getCompanyList, getJobSingleDetail} = useJobContext();
  const [companyListDetails, setCompanyListDetails] = useState([]);
  const [jobLocations, setJobLocations] = useState([]);

  const fetchListedCompany = async() => {
    try {
      const result = await getCompanyList();
      setCompanyListDetails(result?.data);
      //console.log("this is company list", result?.data);
    } catch (error) {
      console.log(error)
    }
  }

  const fetchJobLocations = async () => {
    try {
      const result = await getJobSingleDetail(); // Pass object with key property
      console.log("this is list", result?.data);
      setJobLocations(result?.data)
    } catch (error) { 
      console.error('Error fetching job locations:', error);
    }
  };

 useEffect(() => {
    fetchListedCompany();
    fetchJobLocations();
  }, [])

  return (
    <>
      {/* <div className="container-fluid" style={{ backgroundImage: url(${'https://images.pexels.com/photos/1629236/pexels-photo-1629236.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'}), backgroundSize: 'cover', backgroundPosition: 'center', backgroundAttachment: 'fixed' }} > */}
      <div className='container-fluid'>
        <div className="container-sm py-4">
          <div className="row justify-center items-center py-4">
            <div className="col-lg py-5 px-3 flex flex-col gap-3">
              <p className='text-center text-sm font-semibold'> &#11088; Find Jobs, Hire Talent, Succeed Together</p>
              <h1 className='display-2 font-semibold text-center'>The Perfect Match for <span className='text-prime'>Employers</span> and <span className='text-prime'>Job Seekers</span></h1>
              <p className='text-center'>Welcome to Career tech guru, the ultimate destination for employers and job seekers. Whether you're looking to hire top talent or searching for your dream job, we provide a seamless and efficient platform to connect the right people with the right opportunities.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container-sm py-6 mb-4">
          <div className="row py-6 gap-2">
            <div className="col-lg-4">
              <img src="https://images.pexels.com/photos/416405/pexels-photo-416405.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" className="img-fluid rounded" />
            </div>
            <div className="col-lg">
              <h4 className='uppercase h4 font-bold text-prime'>What we do</h4>
              <p className="display-6">Career Techguru matches awesome people with <span className='font-bold text-prime'>awesome jobs.</span></p>
              <ul className="nav gap-3 py-3">
                <li className='nav-item flex items-center gap-2'>
                  <span><FcOk /></span>
                  <span>We find talented professionals around the world (7 million and counting).</span>
                </li>
                <li className='nav-item flex items-center gap-2'>
                  <span><FcOk /></span>
                  <span>Empower them to prove their aptitude, abilities & attitude are world-class.</span>
                </li>
                <li className='nav-item flex items-center gap-2'>
                  <span><FcOk /></span>
                  <span>Enable companies to recruit the best 1% for premium remote full-time jobs.</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container-sm bg-prime rounded shadow py-6 mb-4">
          <div className="row justify-center py-3">
            <div className="col-lg-10 flex flex-col gap-3">
              <h4 className='uppercase flex text-xl items-center justify-between text-light'><span>What we do</span> <span>our vision</span></h4>
              <p className='display-6 text-light py-4'>Recruitment sucks. So we’re fixing it.</p>
              <div className="row gap-2">
                <div className="col-lg">
                  <div className="box flex items-center gap-3 text-2xl text-light mb-3">
                    <span><IoRibbonSharp /></span>
                    <h4>The Olympics of work</h4>
                  </div>
                  <p className='text-sm text-slate-100'>
                    It’s super hard to qualify—extreme quality standards ensure every single team member is at the top of their game.
                  </p>
                </div>
                <div className="col-lg">
                  <div className="box flex items-center gap-3 text-2xl text-light mb-3">
                    <span><FaHandHoldingUsd /></span>
                    <h4>Premium pay for premium talent</h4>
                  </div>
                  <p className='text-sm text-slate-100'>
                    Over 50% of new hires double or triple their previous pay. Why? Because that’s what the best person in the world is worth.                  </p>
                </div>
                <div className="col-lg">
                  <div className="box flex items-center gap-3 text-2xl text-light mb-3">
                    <span><IoRibbonSharp /></span>
                    <h4>Shortlist by skills, not bias</h4>
                  </div>
                  <p className='text-sm text-slate-100'>
                    We don’t care where you went to school, what color your hair is, or whether we can pronounce your name. Just prove you’ve got the skills.                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-sm py-4">
          <div className="row justify-center items-center py-4">
            <h3 className='uppercase h4 font-bold text-prime'>Top companies hiring from us</h3>
            <span>To find the best person in the world, not the best person in their zipcode.</span>
            <div className="col-lg py-6">
              <Marquee speed={50}>
                <div className="col-lg flex justify-evenly">
                  {companyListDetails?.map((items, idx) => (
                     <div className="card ms-2" key={items.id || idx}>
                      <div className="card-body flex flex-col items-center justify-center">
                        <img src={items?.profilePicture} alt="" className='size-32 img-fluid' />
                      </div>
                    </div>
                  ))}
                </div>
              </Marquee>
            </div>
          </div>
        </div>
        <div className="container-sm bg-prime rounded shadow py-6 mb-4">
          <div className="row justify-center py-3">
            <div className="col-lg-10 flex flex-col gap-3">
              <h4 className='uppercase text-xl text-light'>How it works ?</h4>
              <p className='display-6 text-light'>Applying for a role? Here’s what to expect.</p>
              <span className='text-sm text-slate-200'>We’ve curated a series of steps that take the guesswork (and cognitive bias) out of recruiting the best person.</span>
              <div className="row gap-2 py-6">
                <div className="col-lg">
                  <span className='text-6xl text-light'><GiBrain /></span>
                  <p className='hr bg-light my-3'></p>
                  <div>
                    <h6 className='uppercase text-xl text-light'>step 1</h6>
                    <span className='text-light text-sm'>Pass Cognitive Aptitude Test.</span>
                  </div>
                </div>
                <div className="col-lg">
                  <span className='text-6xl text-light'><FiMessageSquare /></span>
                  <p className='hr bg-light my-3'></p>
                  <div>
                    <h6 className='uppercase text-xl text-light'>step 2</h6>
                    <span className='text-light text-sm'>Pass English Proficiency Test.</span>
                  </div>
                </div>
                <div className="col-lg">
                  <span className='text-6xl text-light'><GiPuzzle /></span>
                  <p className='hr bg-light my-3'></p>
                  <div>
                    <h6 className='uppercase text-xl text-light'>step 3</h6>
                    <span className='text-light text-sm'>Prove Real-World Job Skills.</span>
                  </div>
                </div>
                <div className="col-lg">
                  <span className='text-6xl text-light'><FaUsers /></span>
                  <p className='hr bg-light my-3'></p>
                  <div>
                    <h6 className='uppercase text-xl text-light'>step 4</h6>
                    <span className='text-light text-sm'>Ace An Interview Or Two.</span>
                  </div>
                </div>
                <div className="col-lg">
                  <span className='text-6xl text-light'><GiElvenCastle /></span>
                  <p className='hr bg-light my-3'></p>
                  <div>
                    <h6 className='uppercase text-xl text-light'>step 5</h6>
                    <span className='text-light text-sm'>Accept Job Offer.</span>

                  </div>
                </div>
                <div className="col-lg">
                  <span className='text-6xl text-light'><MdCelebration /></span>
                  <p className='hr bg-light my-3'></p>
                  <div>
                    <h6 className='uppercase text-xl text-light'>step 6</h6>
                    <span className='text-light text-sm'>Celebrate!</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-sm py-4">
          <div className="row justify-center items-center py-4">
            <h3 className='uppercase h4 font-bold text-prime'>Find nearest jobs from you</h3>
            <span>To find the best location near to you.</span>
            <Marquee>

            {jobLocations?.map((item, idx) => (
                <div className='col-lg py-4' key={idx}>
                  <div className="col me-9 border border-primary m-2 py-4 rounded w-44">
                    <h6 className='text-center font-semibold capitalize'>{item}</h6>
                  </div>
                </div>
              ))}

            </Marquee>
          </div>
        </div>
        <div className="container-sm py-4">
          <div className="row">
              <div className="col-lg flex justify-center items-center">
                <img src={banner} alt="" className="img-fluid" />
              </div>
              <div className="col-lg">
                <h2 className='display-3'>About Career Techguru</h2>
                <p className='text-secondary py-3'>Career Techguru is a job seeker and job advertising tool created by the developer team at 
                  ESPS Pvt Ltd. CTG uses web scraping technology to actively interact with 
                  third-party job posting websites and apply to many jobs..</p>
                <button className='btn bg-prime text-light'>Explore more</button>
              </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home