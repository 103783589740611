import React, { useEffect, useState } from 'react';
import EmployerProfileForm from '../EmployerProfileForm';
import { useAdminContext } from '../../../context';
import DOMPurify from "dompurify";
import { VscLinkExternal } from 'react-icons/vsc';
import { CircularLoader } from '../../../components';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { convertTimeIntoMMYYYY } from '../../../utils/dateFilter/DateFilter';
import UpdateInput from '../../../components/modal/UpdateInput';
import { ModalBox } from '../../../components';
import { Link } from 'react-router-dom';


const EmployerProfile = () => {
    const [openModal, setOpenModal] = useState(false);
    const [currentField, setCurrentField] = useState('');
    const [currentValue, setCurrentValue] = useState('');
    const { getAdminsDetailsByAdminId } = useAdminContext();
    const [data, setData] = useState([]);
    const user = JSON.parse(sessionStorage.getItem('data'));

    const getAdminProfileDetails = async () => {
        try {
            const result = await getAdminsDetailsByAdminId(user?.id);
            setData(result?.data?.data);
        } catch (error) {
            console.error(error);
        }
    };
    const handleEditClick = (field, value) => {
        setCurrentField(field);
        setCurrentValue(value);
        setOpenModal(true);
      };
    
      const handleUpdate = (updatedData) => {
        console.log(`Updated ${updatedData.key}: ${updatedData.value}`);
        // Update the local data or refetch from the server if needed
        // setOpenModal(false);
      };
    
      const closeModal = () => {
        setOpenModal(false);
      };

    useEffect(() => {
        getAdminProfileDetails();
    }, []);

    if (data === null) {
        return <CircularLoader />;
    }

    return !data || data.length === 0 ? (
        <EmployerProfileForm />
    ) : (
        <><div className='container-fluid bg-[#fff]'>
            <div className="container-sm bg-light" style={{height:'auto', bottom:'0'}}>
                <div className="row py-4 bg-light">
                    <div className="col-lg-2">
                        <div className="col py-3">
                            <img src="https://i.pravatar.cc/300" alt="" className='w-24 rounded-circle' />
                        </div>
                    </div>
                    <div className="col-lg">
                        <h3 className='text-xl font-semibold capitalize'>{user?.firstName} {user?.lastName}</h3>
                        <ul className="nav gap-2">
                            <li className='nav-item'>
                                {data?.companyContact} |
                            </li>
                            <li className='nav-item'>
                                {data?.companyEmail}
                            </li>
                        </ul>
                        <ul className="nav gap-2">
                            <li className='nav-item'>
                                {data?.companyAddress} |
                            </li>
                            <li className='nav-item'>
                                {data?.city}
                            </li>
                            <li className='nav-item'>
                                {data?.state} |
                            </li>
                            <li className='nav-item'>
                                {data?.pincode}
                            </li>
                        </ul>
                        <ul className="nav items-center gap-2 pt-4">
                            <li className='nav-item'>
                                <a href={data?.links?.github} className='text-2xl' target='_blank' ><FaGithub /> </a>
                            </li>
                            <li className='nav-item'>
                                <a href={data?.links?.linkedin} className='text-2xl text-[#0962C0]' target='_blank' ><FaLinkedin /> </a>
                            </li>
                            <li className='nav-item'>
                                <a href={data?.links?.twitter} className='text-2xl text-gray-500' target='_blank' ><FaXTwitter /> </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <p className='hr bg-slate-200'></p>
                <div className="row py-3">
                    <div className="col-lg">
                        <h4 className='h3'>Basic Detail</h4>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <p><label htmlFor="" className='h6 text-secondary capitalize'>Company Name</label>
                                <Link className='text-primary ms-2' onClick={() => handleEditClick('companyName', data?.companyNameName)}>edit</Link>
                                </p>
                                <p className='capitalize'>{data?.companyName}</p>
                            </div>
                            <div className="col-md-6 mb-3">
                                <p><label htmlFor="" className='h6 text-secondary capitalize'>No of Employee</label>
                                <Link className='text-primary ms-2' onClick={() => handleEditClick('numberOfEmployee', data?.numberOfEmployee)}>edit</Link></p>
                                <p className='capitalize'>{data?.numberOfEmployee}</p>
                            </div>
                            <div className="col-md-6 mb-3">
                                <p><label htmlFor="" className='h6 text-secondary capitalize'>email</label>
                                <Link className='text-primary ms-2' onClick={() => handleEditClick('companyEmail', data?.companyEmail)}>edit</Link></p>
                                <p className='capitalize'>{data?.companyEmail}</p>
                            </div>
                            <div className="col-md-6 mb-3">
                                <p><label htmlFor="" className='h6 text-secondary capitalize'>mobile</label>
                                <Link className='text-primary ms-2' onClick={() => handleEditClick('companyContact', data?.companyContact)}>edit</Link></p>
                                <p className='capitalize'>{data?.companyContact}</p>
                            </div>
                            <div className="col-md-6 mb-3">
                                <p><label htmlFor="" className='h6 text-secondary capitalize'>Website</label>
                                <Link className='text-primary ms-2' onClick={() => handleEditClick('companyWebsite', data?.companyWebsite)}>edit</Link></p>
                                <p className='capitalize'>{data?.companyWebsite}</p>
                            </div>
                            <div className="col-md-6 mb-3">
                                <p><label htmlFor="" className='h6 text-secondary capitalize'>Services</label>
                                <Link className='text-primary ms-2' onClick={() => handleEditClick('service', data?.service)}>edit</Link></p>
                                <p className='flex items-center gap-2'>{data?.service?.map((ser, idx) => (
                                    <span className='p-2 bg-slate-200 rounded'>{ser}</span>
                                ))}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row py-3">
                    <div className="col-lg">
                        <h4 className='h3'>About us</h4>
                        <p className="text-[14px] text-secondary text-justify" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.description) }} />
                    </div>
                </div>
            </div>
        </div>
         {openModal && (
            <ModalBox closeModal={closeModal}>
              <UpdateInput
                title={currentValue}
                field={currentField}
                onUpdate={handleUpdate}
              />
            </ModalBox>
          )}
          </>
        // <div className="container-fluid bg-[#fff] py-4">
        //     <div className="container-sm">
        //         <div className="row py-4 items-center bg-white shadow rounded mb-3">
        //                 <h3 className='h3 capitalize text-gradient my-3'>
        //                     Hello {user?.firstName} {user?.lastName}
        //                 </h3>
        //             <div className="col-md-4">
        //                 <div className="col">
        //                     <img
        //                         src="https://i.pravatar.cc/300"
        //                         alt="Profile"
        //                         className="img-fluid rounded-circle"
        //                         width={150}
        //                     />
        //                 </div>
        //             </div>
        //             <div className="col-md">
        //                 <div className="group">
        //                     <p className='flex items-center gap-2'>
        //                         <span className='text-xl font-bold text-secondary'>{data?.companyName}</span>
        //                         <a href={data?.companyWebsite ?? '#'} className='nav-link' target='_blank' rel='noopener noreferrer'>
        //                             <VscLinkExternal />
        //                         </a>
        //                     </p>                            <p>{data?.companyEmail}</p>
        //                     <p>{data?.companyContact}</p>
        //                     <p>{data?.city}, {data?.state}, {data?.pincode}</p>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="row py-4 bg-white shadow rounded mb-3">
        //             <div className="col-md">
        //                 <div className="group">
        //                     <h3 className='h3 font-bold text-gradient'>Our Services</h3>
        //                     <ul className='nav gap-2 py-4'>
        //                         {data?.service?.map((ser, idx) => (
        //                             <li key={idx}>
        //                                 <span className='p-2 capitalize text-secondary font-semibold rounded border'>{ser}</span>
        //                             </li>
        //                         ))}
        //                     </ul>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="row py-4 bg-white shadow rounded mb-3">
        //             <div className="col-md">
        //                 <div className="group">
        //                     <h3 className='h3 font-bold text-gradient'>Description</h3>
        //                     <p className="text-[14px] text-secondary text-justify" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.description) }} />
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
};

export default EmployerProfile;
