import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import { IoLocationSharp, IoShareSocialSharp } from "react-icons/io5";
import DOMPurify from "dompurify";
import { FaSuitcase } from "react-icons/fa";
import { truncateText } from "../../../utils/stringBuilder/Stringify";
import { convertTimeIntoDDMMYY } from "../../../utils/dateFilter/DateFilter";


const JobCard = ({ data }) => {
  const navigate = useNavigate();


  return (
    <>
      {data?.map((job, idx) => (
        <div key={idx} className="col-lg-9 px-2 relative">
          <div className="card">
            <div className="card-header flex flex-wrap justify-between items-center bg-white">
              <img src={job?.profilePicture} alt="" className="size-11" />
              <span className="text-xs text-secondary">{convertTimeIntoDDMMYY(job?.createdAt)}</span>
            </div>
            <div className="card-body relative">
              <p className="flex items-center justify-between gap-3">
                {job && job?.companyName.length > 0 ? <span className="text-xs font-semibold text-secondary">{job?.companyName}</span> : null}
              </p>
              <Link className="nav-link" to={`/view-jobs-detail/${job?.title}`}><h5 className="text-wrap">{truncateText(job?.title, 4)}</h5></Link>
              <ul className="flex items-center gap-2 my-2">
                <li className="capitalize text-xs flex items-center text-secondary gap-1 px-2 bg-slate-200">
                  <IoLocationSharp />{job?.jobLocation}
                </li>
                <li className="capitalize text-xs flex items-center text-secondary bg-slate-200 px-2 gap-1">
                  <FaSuitcase />{job?.experience}
                </li>
              </ul>
              {/* <p className="text-[14px] text-secondary text-justify" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(truncateText(job?.shortDescription, 40)) }} /> */}
            </div>
            <div className="card-footer flex items-center justify-between">
              <div className="flex items-center">
              <button className="flex justify-center items-center btn px-7 bg-prime text-light">Share <IoShareSocialSharp /> </button>
              <button onClick={() => navigate(`/view-jobs-detail/${job?.title}`)} className="btn px-8 ms-2 bg-prime text-light">Apply</button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default JobCard;
